import styled from "styled-components";

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 15px 0;
`;

const TitleSection = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
`;

const ImageSlider = ({ files }) => {
  if (!files) {
    return null;
  }

  return (
    <SliderContainer>
      <TitleSection>Conoce más</TitleSection>
      {files.map((file, index) => (
        <a href={file.file} key={index}>
          {file.title}
        </a>
      ))}
    </SliderContainer>
  );
};
export default ImageSlider;
