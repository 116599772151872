import styled from "styled-components";
import { Link } from "react-router-dom";

const BlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  max-height: 500px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 20px;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;

  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
`;

const PostContainer = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  height: 100%;
  width: 350px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  hover {
    background-color: rgb(232, 103, 103);
  }
`;

const PostTitle = styled.span`
  font-size: 20px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: normal;
`;

const PostImage = styled.img`
  width: 100%;
  max-height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
`;

const TitleSection = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #fff;
`;

const Container = styled.div`
  width: 100%;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.secondary};
`;

// Función para generar el slug
const generateSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

const BlogsProduct = ({ blogs }) => {
  //   const blogs = require("../../data/blogs");
  if (!blogs) {
    return null;
  }

  return (
    <Container>
      <TitleSection>Blogs</TitleSection>
      <BlogContainer>
        {blogs.map((blog, index) => (
          <Link
            to={`/blog/${generateSlug(blog.title)}`}
            style={{ textDecoration: "none", color: "inherit" }}
            key={index}
          >
            <PostContainer>
              <PostImage src={`/blogImg/${blog.id}.jpg`} alt={blog.title} />
              <PostTitle>{blog.title}</PostTitle>
            </PostContainer>
          </Link>
        ))}
      </BlogContainer>
    </Container>
  );
};
export default BlogsProduct;
