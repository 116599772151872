import React from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import products from "../data/newProducts";
import nutritionPlan from "../data/nutritionPlans";
import content_products from "../data/content-product";
import styled from "styled-components";
import SearchBar from "../components/Search/SearchBar";
import { BsWhatsapp } from "react-icons/bs";
import ContactCard from "../components/Contact/ContactCard";
import { HideText } from "../utils/HideText";
import { Seo } from "../utils/Seo";
import numbersWhatsapp from "../data/numbersWH";
import { logEvent } from "../analytics";
import ModalContact from "../components/ProductDetail/ModalProuctDetail";
import ImageSlider from "../components/ProductDetail/ImageSlider";
import VideosSection from "../components/ProductDetail/VideosSection";
import BlogsProduct from "../components/ProductDetail/Blogs";

const ProductDetail = () => {
  const { productName } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const country = queryParams.get("country") || "";

  const product = products.find((p) => p.name === productName);
  const productMatches = products.filter((p) => p.name === productName);
  const numbersCountry = numbersWhatsapp.find((n) => n.país === country);

  const planNutritional = nutritionPlan.filter((p) => p.name);

  const productContent = content_products.find((p) => p.name === product.name);
  console.log(productContent);

  const handleImageError = (event) => {
    event.target.src = "../productsImg/optimized/default-placeholder.webp"; // Reemplaza con la ruta correcta de la imagen de patentkali
  };

  if (!product) {
    return <div>Producto no encontrado</div>;
  }

  // Filtrar productos relacionados
  let relatedProducts = productMatches.filter(
    (p) => p.hierarchy === product.hierarchy && p.name !== product.name
  );

  // Obtener planes nutricionales relacionados basados en el cultivo del producto
  let relatedPlanes = product.cultivation.filter((cultivo) =>
    planNutritional.some((plan) =>
      plan.name.replace(/^Cultivo de\s+/i, "").includes(cultivo)
    )
  );

  const planesMatch = planNutritional.filter((p) =>
    relatedPlanes.includes(p.name.replace(/^Cultivo de\s+/i, ""))
  );

  //Para enviar eventos a Google Analytics 4
  const handleContactClick = (productName, country) => {
    logEvent(
      "Product Interaction",
      "Click en producto",
      `Producto: ${productName} - País: ${country}`
    );
  };

  const handleCropClick = (cropname) => {
    logEvent("Product Interaction", "Click en cultivo", `Cultivo: ${cropname}`);
  };

  const validCategories = [
    "soluble",
    "npk",
    "mezcla",
    "micronutriente",
    "edafico",
  ];

  const shouldShowButtons =
    (product.hierarchy &&
      validCategories.some((keyword) =>
        product.hierarchy.toLowerCase().includes(keyword.toLowerCase())
      )) ||
    (product.description &&
      validCategories.some((keyword) =>
        product.description.toLowerCase().includes(keyword.toLowerCase())
      ));

  // Si hay menos de 6 productos relacionados, completar con productos aleatorios
  if (relatedProducts.length < 6) {
    const remainingCount = 6 - relatedProducts.length;

    const randomProducts = products
      .filter(
        (p) =>
          p.name !== product.name &&
          p.countries.includes(country) &&
          !relatedProducts.includes(p)
      )
      .sort(() => 0.5 - Math.random())
      .slice(0, remainingCount);

    relatedProducts = [...relatedProducts, ...randomProducts];
  }

  // Seleccionar imagen de acuerdo al país
  const selectedProduct =
    country && productMatches.some((p) => p.countries.includes(country))
      ? productMatches.find((p) => p.countries.includes(country))
      : product;

  const productImage = `../${selectedProduct.image}`;

  return (
    <>
      <Seo
        title={product.name}
        type="product"
        image={productImage}
        description={`Descubre las características, beneficios y ficha técnica de ${product.name} de DISAN Agro. Soluciones diseñadas para maximizar el rendimiento de tus cultivos de manera sostenible.`}
      />
      <Container>
        <SearchBar redirectTo="/search" />
        <DescriptionContainer>
          <Top>
            <LeftSide>
              <img
                src={productImage}
                alt={product.name}
                onError={handleImageError}
                className="product-image"
              />
              <h4>Proveedor</h4>
              <p>{product.supplier}</p>
              <div className="links">
                <div className="link">
                  <h4>¿Te interesa?</h4>
                  {numbersCountry && (
                    <ContactButton
                      href={`https://api.whatsapp.com/send?phone=${numbersCountry.número.replace(
                        /\s/g,
                        ""
                      )}&text=Hola!%20Estoy%20interesado%20en%20el%20producto%20${
                        product.name
                      }%20de%20DISAN%20Agro.`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleContactClick(product.name, country)}
                    >
                      <BsWhatsapp />
                      Comprar
                    </ContactButton>
                  )}
                </div>
              </div>
              <ModalContact
                shouldShowButtons={shouldShowButtons}
                product={product}
              />
              <div style={{ marginTop: "25px" }}>
                <h2>Cultivos</h2>
                <BulletContainer>
                  {product.cultivation.map((item) => (
                    <Bullet key={item}>{item}</Bullet>
                  ))}
                </BulletContainer>
              </div>
            </LeftSide>

            <RightSide>
              <Composition>
                <div className="top">
                  <div className="tile">
                    <h2>{product.name}</h2>
                    <div className="product-desc">
                      <p>{product.description}</p>
                    </div>
                  </div>
                  <div className="tile">
                    <h2>Composición</h2>
                    <CompCard>
                      {Object.entries(product.composition).map(
                        ([key, value], index) => (
                          <p key={index}>
                            {key.replace(/_/g, " ")}: <b>{value}</b>
                          </p>
                        )
                      )}
                    </CompCard>
                  </div>
                </div>
                <div className="benefits">
                  <h2>Beneficios</h2>
                  <BenefitsList>
                    {product.benefits
                      .split(".")
                      .map(
                        (benefit, index) =>
                          benefit.trim() && (
                            <BenefitItem key={index}>
                              {benefit.trim()}
                            </BenefitItem>
                          )
                      )}
                  </BenefitsList>
                </div>

                <RelatedPlans>
                  <h2>Planes Nutricionales</h2>
                  <RelatedPlansList>
                    {planesMatch.map((plan, index) => (
                      <RelatedPlantItem key={index}>
                        <Link
                          to={`/plan/${plan.name}`}
                          onClick={() => handleCropClick(plan.name)}
                        >
                          <img
                            src={plan.image}
                            alt={plan.name}
                            onError={handleImageError}
                          />
                          <p>{plan.name}</p>
                        </Link>
                      </RelatedPlantItem>
                    ))}
                  </RelatedPlansList>
                </RelatedPlans>
              </Composition>
            </RightSide>
          </Top>
        </DescriptionContainer>
        {/* {!productContent ? null : (
          <>
            <ImageSlider files={productContent.files} />
            <VideosSection videos={productContent.videos} />
            <BlogsProduct blogs={productContent.blogs} />
          </>
        )} */}

        <ContactCard title="¿Te interesa este producto?" />
        <RelatedProducts>
          <h2>
            <b>PRODUCTOS</b> RELACIONADOS
          </h2>
          <RelatedProductsList>
            {relatedProducts.map((relatedProduct, index) => (
              <RelatedProductItem key={`${index}-${relatedProduct.name}`}>
                <Link
                  to={`/product/${relatedProduct.name}?country=${product.countries}`}
                  onClick={() => {
                    logEvent(
                      "Product Interaction",
                      "click en producto recomendado",
                      `Producto: ${relatedProduct.name}`
                    );
                  }}
                >
                  <img
                    src={"../" + relatedProduct.image}
                    alt={relatedProduct.name}
                    onError={handleImageError}
                  />
                  <p
                    style={{
                      textTransform: "capitalize",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "150px",
                    }}
                  >
                    {HideText(relatedProduct.name.toLowerCase(), 42)}
                  </p>
                </Link>
              </RelatedProductItem>
            ))}
          </RelatedProductsList>
        </RelatedProducts>
      </Container>
    </>
  );
};

const BenefitsList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;
const BenefitItem = styled.li`
  position: relative;
  padding-left: 10px;
  &::before {
    content: "•";
    position: absolute;
    margin-top: 0.2em;
    left: 0;
    color: ${(props) => props.theme.colors.green};
    font-size: ${(props) => props.theme.fontSizes.medium};
    line-height: 1;
  }
`;
const LeftSide = styled.div`
  width: 100%;
  max-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden; /* Evita que el zoom sobresalga */

  .hojas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .product-image {
    width: 100%;
    max-width: 300px; /* Tamaño máximo */
    height: 300px; /* Altura fija para uniformidad */
    object-fit: contain; /* Ajusta la imagen dentro sin recortarla */
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1); /* Suave efecto de zoom */
    }

    @media (max-width: 768px) {
      max-width: 100%; /* En pantallas pequeñas, la imagen ocupa todo el ancho */
      height: auto; /* La altura se ajusta automáticamente */
    }
  }
`;
const RightSide = styled.div`
  width: 100%;
  max-width: 50%;
  text-align: left;
`;
const RelatedProducts = styled.div`
  width: 100%;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  text-align: left;
  h2 {
    color: ${(props) => props.theme.colors.green};
    font-size: ${(props) => props.theme.fontSizes.normal};
  }
`;

const RelatedPlans = styled.div`
  width: 100%;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const RelatedProductsList = styled.div`
  padding: 20px 0;
  display: flex;
  gap: 20px;
  overflow-x: auto;
`;

const RelatedPlansList = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  padding: 10px 0;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
`;
const RelatedProductItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px; /* Aumentar el tamaño del contenedor */
  text-align: center;

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    width: 100%;
    aspect-ratio: 4 / 3; /* Mantiene la proporción */
    object-fit: contain;
    margin-bottom: 10px;
    &:hover {
      transform: scale(1.1); /* Suave efecto de zoom */
    }
  }

  p {
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const RelatedPlantItem = styled.div`
  display: flex;
  width: 180px;
  flex: 0 0 auto;
  text-align: center;
  align-items: center;

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: contain;
    margin-bottom: 10px;
    &:hover {
      transform: scale(1.1); /* Suave efecto de zoom */
    }
  }

  p {
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.primary};
  }
`;
const Composition = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .benefits {
    margin-top: -20px;
  }
  .product-desc {
    padding: 0px !important;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100%;
    p {
      text-align: left;
      max-width: 80%;
    }
  }
  .top {
    display: flex;
    .tile {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 10px;
    }
  }
`;
const CompCard = styled.div`
  margin-top: 20px;
  align-self: center;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
  color: white;
  background: rgb(215, 164, 69);
  border: 0px solid transparent;
  border-radius: 20px 0px 20px 0px;
  background: linear-gradient(
    45deg,
    rgba(215, 164, 69, 1) 50%,
    rgba(215, 164, 69, 0.4590628487723214) 100%
  );

  p {
    font-size: 14px;
  }
`;
const BulletContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 5px;
  width: 100%;
`;
const Bullet = styled.button`
  padding: 5px 10px !important;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    color: white;
    border-color: ${(props) => props.theme.colors.yellow};
    transition: 0.2s;
    background-color: ${(props) => props.theme.colors.yellow};
  }
`;

const ContactButton = styled.a`
  background-color: ${(props) => props.theme.colors.green};
  color: white;
  width: 100%;
  cursor: pointer;
  border-radius: 20px;
  max-width: 150px;
  display: flex;
  justify-content: center;
  padding: 10px 10px !important;
  align-items: center;
  gap: 5px;
  text-decoration: none;

  svg {
    fill: ${(props) => props.theme.colors.white};
    width: 24px;
    height: auto;
    @media (max-width: 768px) {
      width: 32px;
      height: 32px;
    }
  }
`;
const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .links {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    .link {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .techLinks {
    display: flex;
    align-items: flex-end;
    .link {
      width: 100%;
    }
  }
  img {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
`;
const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  * {
    padding: 2% 2%;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.primary};
  * {
  }
  h2 {
    font-size: ${(props) => props.theme.fontSizes.xlarge};
    font-weight: 400;
  }
`;

export default ProductDetail;
