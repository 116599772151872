import styled from "styled-components";
import Video from "../VideoContainer/VideoContainer";

const VideoGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
  }
`;

const TitleSection = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
`;

const VideosSection = ({ videos }) => {
  if (!videos || videos.length === 0) {
    return null;
  }

  return (
    <Container>
      <TitleSection>Videos</TitleSection>
      <VideoGrid>
        {videos.map((video, index) => (
          <Video key={index} videoId={video.id} />
        ))}
      </VideoGrid>
    </Container>
  );
};
export default VideosSection;
