import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import styled from "styled-components";
import { theme } from "../../theme";
import tierraTextura from "../../assets/tierra textura.webp";
import { logEvent } from "../../analytics";

// Load the south-america.json file
import southAmerica from "../../assets/south-america.json";

// Styled-components for responsive design
const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url(${tierraTextura});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

const CountryList = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background-color: #f9f9f9;
  }
`;

const CountryItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 10px 14px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  text-align: center;
  z-index: 100;
`;

const CopyMessage = styled.div`
  font-size: 12px;
  margin-top: 5px;
  color: ${theme.colors.yellow};
  cursor: pointer;
`;

const CopiedNotification = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: ${theme.colors.green};
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

const InteractiveMap = () => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipNumber, setTooltipNumber] = useState("");
  const [showCopied, setShowCopied] = useState(false);

  const handleMouseEvents = (country, number) => (event) => {
    if (event.type === "mouseenter") {
      setTooltipContent(country);
      setTooltipNumber(number);
    } else if (event.type === "mouseleave") {
      setTooltipContent("");
      setTooltipNumber("");
    }
  };

  const handleClick = (number, country) => () => {
    navigator.clipboard.writeText(number).then(() => {
      setShowCopied(true);

      // Enviar evento a Google Analytics
      logEvent("Mapa interactivo", "Click número país", `País: ${country}`);

      setTimeout(() => setShowCopied(false), 2000);
    });
  };

  const markers = [
    {
      name: "Colombia",
      number: "+57 3183699770",
      coordinates: [-74.2973, 4.5709],
      geoName: "Colombia",
    },
    {
      name: "Perú",
      number: "+51 965407186",
      coordinates: [-75.0152, -9.1899],
      geoName: "Peru",
    },
    {
      name: "Ecuador",
      number: "+593 989563036",
      coordinates: [-78.4678, -1.8312],
      geoName: "Ecuador",
    },
    {
      name: "Venezuela",
      number: "+58 4244583420",
      coordinates: [-66.5897, 6.4238],
      geoName: "Venezuela",
    },
    {
      name: "Panamá",
      number: "+57 3176612228",
      coordinates: [-80.7821, 8.538],
      geoName: "Panama",
    },
    {
      name: "Bolivia",
      number: "+51 997629111",
      coordinates: [-63.5887, -16.2902],
      geoName: "Bolivia",
    },
  ];

  const importantCountries = markers.map((marker) => marker.geoName);

  return (
    <>
      {/* Desktop Map */}
      <MapContainer>
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            scale: 430,
            center: [-70, -24],
          }}
          style={{ width: "100%", height: "auto", position: "relative" }}
        >
          {/* Definir fondo en el SVG */}
          <defs>
            <pattern
              id="mapBackground"
              patternUnits="userSpaceOnUse"
              width="100%"
              height="100%"
            >
              <image
                href={tierraTextura}
                x="0"
                y="0"
                width="100%"
                height="100%"
                preserveAspectRatio="xMidYMid slice"
              />
            </pattern>
          </defs>

          {/* Fondo detrás del mapa */}
          <rect width="100%" height="100%" fill="url(#mapBackground)" />
          <Geographies geography={southAmerica}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const normalizeString = (str) =>
                  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                const isImportant = importantCountries.some(
                  (country) =>
                    normalizeString(country) ===
                    normalizeString(geo.properties.name)
                );

                const countryData = markers.find(
                  (marker) =>
                    normalizeString(marker.geoName) ===
                    normalizeString(geo.properties.name)
                );

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    style={{
                      default: {
                        fill: isImportant
                          ? theme.colors.green
                          : theme.colors.secondary,
                        outline: "none",
                        cursor: isImportant ? "pointer" : "default",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "25rem",
                      },
                      hover: {
                        fill: isImportant
                          ? theme.colors.yellow
                          : theme.colors.secondary,
                        outline: "none",
                      },
                      pressed: {
                        fill: isImportant ? theme.colors.lightGreen : undefined,
                        outline: "none",
                      },
                    }}
                    {...(isImportant && countryData
                      ? {
                          onMouseEnter: handleMouseEvents(
                            countryData.name,
                            countryData.number
                          ),
                          onMouseLeave: handleMouseEvents("", ""),
                          onClick: handleClick(
                            countryData.number,
                            countryData.name
                          ),
                        }
                      : {})}
                  />
                );
              })
            }
          </Geographies>
          {markers.map(({ name, number, coordinates }) => (
            <Marker key={name} coordinates={coordinates}>
              <circle
                r={8}
                fill={theme.colors.yellow}
                stroke="#fff"
                strokeWidth={1}
                style={{
                  pointerEvents: "none",
                }}
              />
              <text
                textAnchor="middle"
                y={-15}
                fill="white"
                fontSize={16}
                fontWeight="bold"
                style={{
                  pointerEvents: "none",
                }}
              >
                {name}
              </text>
            </Marker>
          ))}
        </ComposableMap>

        {tooltipContent && (
          <Tooltip $visible={!!tooltipContent}>
            <div>
              {tooltipContent}: {tooltipNumber}
            </div>
            <CopyMessage>
              Click para copiar el número al portapapeles
            </CopyMessage>
          </Tooltip>
        )}

        <CopiedNotification $visible={showCopied}>
          ¡Número copiado al portapapeles!
        </CopiedNotification>
      </MapContainer>

      {/* Mobile Country List */}
      <CountryList>
        {markers.map(({ name, number }) => (
          <CountryItem key={name} onClick={handleClick(number, name)}>
            <span>{name}</span>
            <span>{number}</span>
          </CountryItem>
        ))}
      </CountryList>
    </>
  );
};

export default InteractiveMap;
