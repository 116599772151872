import React from "react";
import fondoCampo from "../assets/fondo campo.webp";
import banner20anios from "../assets/20 años_Banner WEB.png";
import styled from "styled-components";
import ButtonLink from "../components/ButtonLink";
import siembra from "../assets/disan-siembra@2x.png";
import pacto from "../assets/Logo_Pacto_Global_UN.png";
import SelloOrganico from "../assets/sostenibilidad/Sello organico.png";
import ConctactForm from "../components/Contact/ContactCard";
import AlliesSwiper from "../components/alliesCards";
import { useNavigate } from "react-router-dom";
import { importImages } from "../utils/ImportImages";
import OrganicProducts from "../components/Home/OrganicProducts";
import InteractiveMap from "../components/Map/InteractiveMapContact";
import SearchBar from "../components/Search/SearchBar";
import { Seo } from "../utils/Seo";

const Explain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.2rem;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const Impulsamos = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
  padding: 5% 2%;
  a {
    margin-top: 5%;
  }
`;
const ContenedorExplain = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding-bottom: 5%;
  color: ${(props) => props.theme.colors.primary};
  transition: color 0.5s ease-in-out;

  .containerImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    img {
      width: 100px;
      transition: scale 0.2s ease-in-out;
    }

    p {
      font-weight: regular;
    }
  }

  div:not(.containerImg) {
    &:hover {
      img {
        scale: 1.2;
      }
      color: ${(props) => props.theme.colors.green};
      cursor: pointer;
    }
  }

  div:not(.last):not(.containerImg) {
    position: relative; /* Añadir posición relativa para el contenedor del borde */

    &::after {
      content: "";
      position: absolute;
      top: 30%; /* Ajusta esta propiedad para la altura del borde */
      right: 0;
      width: 1px;
      height: 50px; /* Ajusta esta propiedad para la altura del borde */
      background-color: ${(props) => props.theme.colors.green};
    }
    @media (max-width: 480px) {
      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Banner = styled.div`
  margin-bottom: -6px;
  img {
    width: 100%;
    height: auto;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const BackgroundContainer = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.2)
    ),
    url("${fondoCampo}");
  background-size: cover;
  background-position: top;
  width: 100%;
  height: fit-content; // Adjust the height as needed
  display: flex;
  flex-direction: column;
  padding: 5% 2%;
  gap: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 5%;
    min-height: 350px;
    font-size: 1.5rem;
    background-position: center;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 5%;
  align-items: CE;
  color: ${(props) => props.theme.colors.white};
  h2 {
    font-size: 3rem;
  }
`;
const ExtraInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5%;
  padding: 5% 2%;
  background-color: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
  @media (min-width: 480px) {
    flex-direction: row;
    padding: 0;
  }
`;
const ExtraContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 3rem;
  }
  @media (min-width: 480px) {
    flex-direction: row;
    gap: 10px;
    // gap: 5%;
  }
`;

const Allies = styled.div`
  width: 100%;
  max-width: 90%;
  margin: 5% 2%;
`;

const Home = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const alliesImages = importImages(
    require.context("../assets/allies", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <>
      <Seo
        title="Inicio"
        type="website"
        description="Bienvenido a DISAN Agro, líderes en soluciones sostenibles para la agricultura. Descubre productos innovadores, asesoría experta y nuestro compromiso con el crecimiento agrícola en Latinoamérica."
      />
      <Container>
        <Banner>
          <a
            href="https://www.disanagro.com/blog/20-aos-innovando-y-transformando-la-agricultura-en-latinoamrica"
            rel="noopener noreferrer"
          >
            <img src={banner20anios} alt="Banner NPK" />
          </a>
        </Banner>

        <SearchBar />
        <OrganicProducts />

        <Explain>
          <Impulsamos>
            <p>
              <b>Impulsamos iniciativas</b> <br></br>
              <b>sostenibles</b> que generan impacto en
              <b> Latinoamérica</b>
            </p>
            <ButtonLink to="/disan-siembra" variant="secondary">
              Descúbrelas aquí
            </ButtonLink>
          </Impulsamos>
          <ContenedorExplain>
            <div onClick={() => handleNavigate("/disan-siembra")}>
              <div className="containerImg">
                <img
                  src={siembra}
                  alt="DISAN Siembra"
                  style={{ width: "150px" }}
                />
              </div>
              <p>
                <b>DISAN Siembra</b>
                <br></br>Restauración de vida
              </p>
            </div>
            <div onClick={() => handleNavigate("/certificacion-organica")}>
              <div className="containerImg">
                <img
                  src={SelloOrganico}
                  alt="Certificación Organica"
                  style={{ width: "150px" }}
                />
              </div>
              <p>
                <b>Certificación Organica</b>
                <br></br>Multicertificación
              </p>
            </div>
            <div
              className="last"
              onClick={() => handleNavigate("/pacto-global")}
            >
              <div className="containerImg">
                <img src={pacto} alt="Pacto Global" />
              </div>
              <p>
                <b>Pacto global</b>
                <br></br>Alineados con los ODS
              </p>
            </div>
          </ContenedorExplain>
        </Explain>

        <BackgroundContainer>
          <Content>
            <h2>+20</h2>
            <p>Proveedores</p>
          </Content>
          <Content>
            <h2>+200</h2>
            <p>Productos en el portafolio</p>
          </Content>
          <Content>
            <h2>+650</h2>
            <p>Clientes</p>
          </Content>
        </BackgroundContainer>

        <ExtraInfo>
          <ExtraContent>
            <h2>6</h2>
            <p>Países</p>
          </ExtraContent>
          <ExtraContent>
            <h2>+50</h2>
            <p>Agrónomos</p>
          </ExtraContent>
          <ExtraContent>
            <h2>40</h2>
            <p>Regiones de cobertura</p>
          </ExtraContent>
        </ExtraInfo>

        {/* <ColombiaMap /> */}
        <InteractiveMap />
        <ConctactForm />

        <Allies>
          <AlliesSwiper images={alliesImages} />
        </Allies>
      </Container>
    </>
  );
};

export default Home;
