import React from "react";
import styled from "styled-components";

const VideoWrapper = styled.div`
  position: relative;
  width: 90%; /* Por defecto, ocupa el 90% del ancho */
  max-width: 1200px; /* Limita el ancho máximo */
  margin: 2rem auto; /* Centrado horizontal y espacio vertical */
  border-radius: 20px; /* Bordes redondeados */
  overflow: hidden; /* Evita desbordamientos */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Sombra elegante */
  background-color: #000;

  @media (max-width: 768px) {
    width: 95%; /* Más ancho en pantallas pequeñas */
  }

  iframe {
    width: 100%; /* El iframe ocupa todo el ancho del contenedor */
    height: auto; /* El alto se ajusta automáticamente manteniendo el contenido visible */
    aspect-ratio: 16 / 9; /* Asegura que el iframe mantenga una proporción 16:9 */
    border: 0; /* Elimina el borde predeterminado */
  }
`;

const ModernYouTubeVideo = ({ videoId }) => {
  return (
    <VideoWrapper>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&controls=1&loop=1`}
        title="YouTube video"
        allow="encrypted-media; picture-in-picture"
        allowFullScreen
      />
    </VideoWrapper>
  );
};

export default ModernYouTubeVideo;
