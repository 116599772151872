const content_products = [
  {
    name: "Urea Nutrisphere",
    videos: [{ id: "lxtBtF196To" }],
    files: [
      {
        title: "Folleto Perú",
        file: "contents/files/folleto versión Perú .pdf",
      },
    ],
    blogs: [
      {
        title: "",
        description: "",
        image: "",
      },
    ],
  },
  {
    name: "Map Avail 10-50-0",
    videos: [{ id: "lxtBtF196To" }],
    files: [
      {
        title: "",
        file: "",
      },
    ],
    blogs: [
      {
        title: "",
        description: "",
        image: "",
      },
    ],
  },
  {
    name: "Patentkali",
    videos: [{ id: "lxtBtF196To" }],
    files: [
      {
        title: "",
        file: "",
      },
    ],
    blogs: [
      {
        title: "",
        description: "",
        image: "",
      },
    ],
  },
];

module.exports = content_products;
