import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { HiChevronDown } from "react-icons/hi"; // Ícono para el dropdown
import logoDisan from "../../assets/logo-disan-agro.png";
// import Search from "./Search";
// import LanguageSelector from "./LanguageSelector";

// Estilo para el logo
const Logo = styled.div`
  img {
    width: 100%;
    max-width: 150px;
    min-width: 100px;
    height: auto;
  }

  @media (max-width: 768px) {
    img {
      max-width: 100px;
    }
  }
`;

// Contenedor para los enlaces principales
const LinksContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

// Estilo para cada enlace de navegación
const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 10px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  border-radius: 5px;
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;

  &:hover,
  &.active {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.green};
  }
`;

// Botón para el dropdown
const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 10px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  border-radius: 5px;
  padding: 5px 10px;
  background: none;
  height: 100%;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: inherit;
  font-family: inherit;

  &:hover,
  &.active {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.green};
  }

  svg {
    margin-left: 5px; /* Espaciado entre texto e ícono */
    font-size: ${(props) => props.theme.fontSizes.normal};
  }
`;

// Contenedor para el submenú de sostenibilidad
const DropdownMenu = styled.div`
  position: relative;
`;

// Estilo para el submenú desplegable
const SubMenu = styled.div`
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  position: absolute;
  top: 40px;
  left: 0px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000;
  min-width: 200px;
  padding: 10px 5px;

  a {
    width: 100%;
    margin: 0;
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;

    &:hover {
      background-color: ${(props) => props.theme.colors.green};
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

// Contenedor principal de la barra de navegación
const NavBarContainer = styled.nav`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 20px;
`;

// Contenedor derecho
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NavBar = () => {
  const [isSustainabilityOpen, setIsSustainabilityOpen] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef();

  // Verifica si la ruta actual pertenece a "Sostenibilidad" o cualquiera de sus subrutas
  const isSustainabilityActive = [
    "/disan-siembra",
    "/pacto-global",
    "/certificacion-organica",
    "/empaques-sostenibles",
  ].includes(location.pathname);

  const toggleSustainabilityMenu = () => {
    setIsSustainabilityOpen((prev) => !prev); // Alterna el estado del submenú
  };

  // Cierra el menú si se hace clic fuera de él
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsSustainabilityOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <NavBarContainer>
      {/* Logo */}
      <Logo>
        <Link to="/">
          <img src={logoDisan} alt="DISAN Agro Logo" />
        </Link>
      </Logo>

      {/* Enlaces de navegación */}
      <LinksContainer>
        <NavLink
          to="/about-us"
          className={location.pathname === "/about-us" ? "active" : ""}
        >
          Nosotros
        </NavLink>
        <NavLink
          to="/product-search"
          className={location.pathname === "/product-search" ? "active" : ""}
        >
          Portafolio
        </NavLink>
        <NavLink
          to="/soluciones-tecnologicas"
          className={
            location.pathname === "/soluciones-tecnologicas" ? "active" : ""
          }
        >
          Soluciones
        </NavLink>
        <DropdownMenu ref={dropdownRef}>
          {/* Botón "Sostenibilidad" con ícono */}
          <DropdownButton
            onClick={toggleSustainabilityMenu}
            className={isSustainabilityActive ? "active" : ""}
          >
            Sostenibilidad
            <HiChevronDown />
          </DropdownButton>
          <SubMenu $isOpen={isSustainabilityOpen}>
            <NavLink
              to="/disan-siembra"
              onClick={() => setIsSustainabilityOpen(false)}
            >
              Disan Siembra
            </NavLink>
            <NavLink
              to="/pacto-global"
              onClick={() => setIsSustainabilityOpen(false)}
            >
              Pacto Global
            </NavLink>
            <NavLink
              to="/certificacion-organica"
              onClick={() => setIsSustainabilityOpen(false)}
            >
              Certificacion Orgánica
            </NavLink>
            {/* <NavLink
              to="/empaques-sostenibles"
              onClick={() => setIsSustainabilityOpen(false)}
            >
              Empaques Sostenibles
            </NavLink> */}
          </SubMenu>
        </DropdownMenu>
        <NavLink
          to="/Blog"
          className={location.pathname === "/Blog" ? "active" : ""}
        >
          Blog
        </NavLink>
      </LinksContainer>

      {/* Buscador y selector de idioma */}
      <RightContainer>
        {/* <Search />
        <LanguageSelector /> */}
      </RightContainer>
    </NavBarContainer>
  );
};

export default NavBar;
