import React, { useState } from "react";
import styled from "styled-components";
import bannerImage from "../assets/contacto.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ColorTab from "../components/colorTab";
import InteractiveMap from "../components/Map/InteractiveMapContact";
import { Seo } from "../utils/Seo";
import { URL_SHEET } from "../utils/sheetGoogle";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  font-size: ${(props) => props.theme.fontSizes.normal};
  overflow-x: hidden; /* Evita el scroll horizontal */

  @media (min-width: 1280px) {
    font-size: ${(props) =>
      props.theme.fontSizes.large}; /* Tamaño de fuente más grande */
  }
`;

const Description = styled.div`
  display: flex;
  position: relative;
  padding: 5% 10%;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  width: 100%;
  text-align: center;

  p {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      max-width: 50vw;
    }
  }

  @media (min-width: 1280px) {
    font-size: ${(props) =>
      props.theme.fontSizes.large}; /* Ajustar para pantallas grandes */
  }
`;

const Hero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(props) => props.theme.colors.green};
  color: white;
  width: 100%;
  font-size: inherit;
  padding: 5% 10%;

  p {
    width: 100%;
    max-width: 70vw;

    @media (min-width: 768px) {
      max-width: 50vw;
    }
  }

  @media (max-width: 768px) {
    padding: 5% 5%; /* Ajuste de padding en dispositivos móviles */
  }

  @media (min-width: 1280px) {
    font-size: ${(props) =>
      props.theme.fontSizes.large}; /* Ajustar tamaño de fuente */
  }
`;

const BannerImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 600px;
`;

const FormContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const FormTitle = styled.h2`
  color: ${(props) => props.theme.colors.green};
  font-size: ${(props) => props.theme.fontSizes.large};
  margin-bottom: 20px;
  font-weight: bold;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 5px;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.primary};

  &:focus {
    border-color: ${(props) => props.theme.colors.green};
    outline: none;
    box-shadow: 0 0 5px ${(props) => props.theme.colors.lightGreen};
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 5px;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.primary};
  resize: none;
  height: 80px;

  &:focus {
    border-color: ${(props) => props.theme.colors.green};
    outline: none;
    box-shadow: 0 0 5px ${(props) => props.theme.colors.lightGreen};
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  background: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.medium};
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background 0.3s ease;

  &:hover {
    background: ${(props) => props.theme.colors.yellow};
  }
`;

const Contact = () => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    country: "",
    email: "",
    phone: "",
    crop: "",
    message: "",
    type: "contacto",
  });

  // Validate form
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Campo obligatrio";
    }

    if (!formData.company.trim()) {
      newErrors.company = "Campo obligatrio";
    }

    if (!formData.country.trim()) {
      newErrors.country = "Campo obligatrio";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Campo obligatrio";
    }

    if (!formData.crop.trim()) {
      newErrors.crop = "Campo obligatrio";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Campo obligatrio";
    } else if (
      /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/.test(formData.email)
    ) {
      newErrors.email = "Ingrese una dirección de correo electrónico válida";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Campo obligatrio";
    } else if (formData.message.trim().length < 10) {
      newErrors.message = "El mensaje debe tener al menos 10 caracteres";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      fetch(URL_SHEET, {
        method: "POST",
        body: JSON.stringify(formData),
      })
        .then((res) => {
          toast.success("Mensaje enviado con éxito");

          // Reiniciar el formulario
          setFormData({
            name: "",
            company: "",
            country: "",
            email: "",
            phone: "",
            crop: "",
            message: "",
          });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Seo
        title="Contacto"
        type="website"
        description="¿Necesitas ayuda o más información? Ponte en contacto con DISAN Agro. Nuestro equipo está listo para asesorarte con soluciones agrícolas personalizadas y sostenibles."
      />
      <ContactContainer>
        <BannerImg src={bannerImage} alt="Contact Us" />
        <Description>
          <ColorTab />
          <FormContainer>
            <FormTitle>Para más información</FormTitle>
            <StyledForm onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="Nombre completo"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors && <p>{errors.name}</p>}

              <Input
                type="text"
                placeholder="Empresa"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
              {errors && <p>{errors.company}</p>}
              <Input
                type="text"
                placeholder="País"
                name="country"
                value={formData.country}
                onChange={handleChange}
              />
              {errors && <p>{errors.country}</p>}
              <Input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors && <p>{errors.email}</p>}
              <Input
                type="tel"
                placeholder="Teléfono"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors && <p>{errors.phone}</p>}
              <Input
                type="text"
                placeholder="Cultivo de interés"
                name="crop"
                value={formData.crop}
                onChange={handleChange}
              />
              {errors && <p>{errors.crop}</p>}
              <TextArea
                placeholder="¿En qué podemos ayudarte?"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              {errors && <p>{errors.message}</p>}
              <SubmitButton type="submit">Enviar</SubmitButton>
            </StyledForm>
          </FormContainer>
          <ToastContainer />
        </Description>
        <Hero>
          <p>Somos protagonistas de la agricultura sostenible del mañana</p>
        </Hero>

        <InteractiveMap />
      </ContactContainer>
    </>
  );
};

export default Contact;
