import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edaficos } from "../../assets/new icons/edaficos.svg";
import { ReactComponent as Foliares } from "../../assets/new icons/foliares.svg";
import { ReactComponent as Biofertilizantes } from "../../assets/new icons/biofertilizantes.svg";
import { ReactComponent as Solubles } from "../../assets/new icons/solubles.svg";
import { ReactComponent as Sustratos } from "../../assets/new icons/sustratos.svg";
import { ReactComponent as Pqua } from "../../assets/new icons/pqua icon.svg";
import { ReactComponent as ControlBiologico } from "../../assets/new icons/control-biologico.svg";
import searchIcon from "../../assets/search-icon.png";
import Icon from "./IconsForSearch";
import { logEvent } from "../../analytics";

const SearchBar = ({ placeholder = "Buscar producto o nutriente" }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim() !== "") {
      navigate(`/product-search?q=${encodeURIComponent(searchTerm)}`);
      logEvent("home", "buscar producto", `Termino busqueda: ${searchTerm}`); // Enviar búsqueda a GA4
    }
  };

  const handleIconClick = (hierarchy) => {
    navigate(`/product-search?hierarchy=${encodeURIComponent(hierarchy)}`);
    logEvent("home", "clic categoría", `Categoría: ${hierarchy}`); // Enviar categoria a GA4
  };

  return (
    <SearchContainer>
      <IconsContainer>
        <Icon
          svg={<Edaficos />}
          title="Edáficos"
          onClick={() => handleIconClick("edáfico")}
        />
        <Icon
          svg={<Solubles />}
          title="Solubles"
          onClick={() => handleIconClick("solubles")}
        />
        <Icon
          svg={<Foliares />}
          title="Foliares"
          onClick={() => handleIconClick("foliar")}
        />
        <Icon
          svg={<Sustratos />}
          title="Sustratos"
          onClick={() => handleIconClick("sustratos")}
        />
        <Icon
          svg={<ControlBiologico />}
          title="Control Biológico"
          onClick={() => handleIconClick("control-biologico")}
        />
        <Icon
          svg={<Biofertilizantes />}
          title="Biofertilizantes"
          onClick={() => handleIconClick("biofertilizantes")}
        />
        <Icon
          svg={<Pqua />}
          title="PQUA"
          onClick={() => handleIconClick("PQUA")}
        />
      </IconsContainer>

      <SearchForm onSubmit={handleSubmit}>
        <SearchInput
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearch}
        />
      </SearchForm>
    </SearchContainer>
  );
};

export default SearchBar;

// 🟢 Estilos

const SearchContainer = styled.div`
  background-color: ${(props) => props.theme.colors.yellow};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 2%;
  gap: 20px;
  width: 100%;
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 1rem;
  max-width: 70vw;

  @media (min-width: 768px) {
    justify-content: center;
    gap: 50px;
    width: 100%;
  }
`;

const SearchForm = styled.form`
  width: 100%;
  max-width: 70vw;
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  padding: 10px;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  border-radius: 20px;
  background-origin: content-box;
  color: ${(props) => props.theme.colors.green};
  font-size: 1.1rem;
  text-align: inherit;
  appearance: none;

  &:focus {
    outline: none;
  }
`;
