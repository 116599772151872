import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { BsEye } from "react-icons/bs"; // Icono de "ver"
import { logEvent } from "../../analytics";
import { URL_SHEET } from "../../utils/sheetGoogle";

const ModalContact = ({ product, shouldShowButtons }) => {
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    country: "",
    email: "",
    phone: "",
    crop: "",
    type: "ficha_tecnica",
  });

  // Validate form
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Campo obligatrio";
    }

    if (!formData.country.trim()) {
      newErrors.country = "Campo obligatrio";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Campo obligatrio";
    }

    if (!formData.crop.trim()) {
      newErrors.crop = "Campo obligatrio";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Campo obligatrio";
    } else if (
      /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/.test(formData.email)
    ) {
      newErrors.email = "Ingrese una dirección de correo electrónico válida";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      fetch(URL_SHEET, {
        method: "POST",
        body: JSON.stringify(formData),
      })
        .then((res) => {
          // Reiniciar el formulario
          setFormData({
            name: "",
            country: "",
            email: "",
            phone: "",
            crop: "",
          });
          setFormSubmitted(true); // Al enviar el formulario, se activan los botones
        })
        .catch((err) => console.log(err));
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="techLinks">
      {shouldShowButtons && (
        <>
          <div className="hojas">
            <p>Ficha Técnica / Hoja de Seguridad</p>
            <ViewButton onClick={toggleModal}>
              <BsEye /> Ver
            </ViewButton>
          </div>

          {showModal && (
            <ModalOverlay>
              <ModalContent>
                {!formSubmitted ? (
                  <form onSubmit={handleSubmit}>
                    <h2>Ingrese su información</h2>
                    <input
                      type="text"
                      placeholder="Nombre Completo"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors && <p>{errors.name}</p>}
                    <input
                      type="email"
                      placeholder="Correo Electrónico"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors && <p>{errors.email}</p>}
                    <input
                      type="text"
                      placeholder="País"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                    />
                    {errors && <p>{errors.country}</p>}
                    <input
                      type="tel"
                      placeholder="Teléfono"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors && <p>{errors.phone}</p>}
                    <input
                      type="text"
                      placeholder="Cultivo"
                      name="crop"
                      value={formData.crop}
                      onChange={handleChange}
                    />
                    {errors && <p>{errors.crop}</p>}
                    <button type="submit">Enviar</button>
                    <CloseButton onClick={toggleModal}>✖</CloseButton>
                  </form>
                ) : (
                  <div>
                    <h2>Gracias por su interés</h2>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {product.fichaT && (
                        <DownloadButton
                          href={product.fichaT}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() =>
                            logEvent(
                              "Descarga",
                              "Click ficha técnica",
                              `Ficha Técnica - ${product.name}`
                            )
                          }
                        >
                          Descargar Ficha Técnica
                        </DownloadButton>
                      )}

                      {product.hojaS && (
                        <DownloadButton
                          href={product.hojaS}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() =>
                            logEvent(
                              "Descarga",
                              "Click hoja de seguridad",
                              `Hoja de Seguridad - ${product.name}`
                            )
                          }
                        >
                          Descargar Hoja de Seguridad
                        </DownloadButton>
                      )}
                    </div>
                    <CloseButton onClick={toggleModal}>✖</CloseButton>
                  </div>
                )}
              </ModalContent>
            </ModalOverlay>
          )}
        </>
      )}
    </div>
  );
};

const ViewButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
  border: none;
  padding: 10px 20px !important;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-weight: bold;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    background: ${(props) => props.theme.colors.yellow};
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    font-size: 1.2rem;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo más sutil */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px); /* Agrega un efecto de desenfoque */
`;

const ModalContent = styled.div`
  background: ${(props) => props.theme.colors.white};
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  width: 90%;
  max-width: 450px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3); /* Sombra moderna */
  position: relative;
  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: ${(props) => props.theme.colors.primary};
  }

  input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid ${(props) => props.theme.colors.secondary};
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;

    &:focus {
      border-color: ${(props) => props.theme.colors.green};
      outline: none;
      box-shadow: 0px 0px 8px ${(props) => props.theme.colors.green};
    }
  }

  button {
    background: ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.white};
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    &:hover {
      background: ${(props) => props.theme.colors.yellow};
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background: ${(props) => props.theme.colors.white}; /* Fondo sutil */
  border: 1px solid ${(props) => props.theme.colors.red}; /* Borde fino */
  color: ${(props) => props.theme.colors.red}; /* Color del icono */
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%; /* Forma circular */
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */

  &:hover {
    background: ${(props) =>
      props.theme.colors.red}; /* Cambio de color al pasar el mouse */
    color: ${(props) => props.theme.colors.white};
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }
`;

const DownloadButton = styled.a`
  background-color: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  max-width: 200px;
  display: flex;
  justify-content: center;
  padding: 12px 20px;
  align-items: center;
  border: none;
  gap: 8px;
  text-decoration: none;
  transition: background 0.3s ease-in-out;
  margin: 10px;
  text-align: center;

  &:hover {
    background: ${(props) => props.theme.colors.yellow};
  }

  svg {
    fill: ${(props) => props.theme.colors.white};
    width: 20px;
    height: auto;
  }
`;

export default ModalContact;
