import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { HideText } from "../../utils/HideText";
import { logEvent } from "../../analytics";

// Skeleton Loader Estilizado
const SkeletonLoader = styled.div`
  width: 100%;
  max-width: 150px;
  height: 150px;
  background: linear-gradient(
    90deg,
    rgba(200, 200, 200, 0.2) 25%,
    rgba(150, 150, 150, 0.3) 50%,
    rgba(200, 200, 200, 0.2) 75%
  );
  background-size: 400% 100%;
  animation: shimmer 1.5s infinite linear;
  border-radius: 10px;

  @keyframes shimmer {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
`;

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  exit: { opacity: 0, y: -20, transition: { duration: 0.3 } },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5% 2%;
  gap: 20px;
  h1 {
    font-size: 14px;
    color: ${(props) => props.theme.colors.primary};
    font-weight: normal;
    text-align: left;
  }
`;

const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const PageButton = styled.button`
  background-color: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;

  &:disabled {
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.white};
    cursor: not-allowed;
  }
  &:hover:not(:disabled) {
    background-color: ${(props) => props.theme.colors.secondary};
    transition: 0.3s;
    background-color: ${(props) => props.theme.colors.yellow};
  }
  &:focus {
    outline: none;
  }
`;

const List = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;

  @media (min-width: 480px) {
    justify-content: flex-start;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  text-align: center;

  h2 {
    font-size: 18px;
    font-weight: normal;
    max-width: 150px;
  }

  img {
    width: 100%;
    // max-width: 150px;
    height: auto;
    max-height: 150px;
    object-fit: contain;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }
`;

const ProductLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 1.5rem;
`;

const ProductsList = ({ products, showRecommendation, country }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [imageLoading, setImageLoading] = useState({});

  const [listKey, setListKey] = useState(0);
  useEffect(() => {
    // Resetear el estado de carga al cambiar de página o de productos
    setImageLoading({});
  }, [products, currentPage]);
  useEffect(() => {
    // Cuando cambian los productos, se actualiza la clave del listado para reiniciar la animación
    setListKey((prevKey) => prevKey + 1);
    setCurrentPage(1); // Resetear la página a 1 cuando se aplican filtros
  }, [products]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(products.length / itemsPerPage)) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1;
        setListKey((prevKey) => prevKey + 1); // Actualiza la clave para forzar la animación
        return newPage;
      });
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1;
        setListKey((prevKey) => prevKey + 1); // Actualiza la clave para forzar la animación
        return newPage;
      });
    }
  };

  const handleImageLoad = (index) => {
    setImageLoading((prev) => ({ ...prev, [index]: false }));
  };

  const handleImageError = (event, index) => {
    event.target.src = "productsImg/default-placeholder.png"; // Imagen fallback
    setImageLoading((prev) => ({ ...prev, [index]: false }));
  };

  const titleProduct = (item) => {
    const text = ["MKP", "KP", "NKS", "NK"];
    return text.some((keyword) => item.includes(keyword))
      ? HideText(item, 42)
      : HideText(item.toLowerCase(), 42);
  };

  return (
    <Container>
      {showRecommendation && (
        <NoResultsContainer>
          <h2>¡No encontramos coincidencias! 🤔</h2>
          <p>
            Prueba ajustando los filtros o explora estos productos recomendados.
          </p>
        </NoResultsContainer>
      )}

      {!showRecommendation && (
        <h1>
          Mostrando {currentItems.length} resultados de {products.length}
        </h1>
      )}
      <AnimatePresence mode="wait">
        <List
          key={listKey} // Cambia la clave cuando los productos o la página se actualicen
          variants={pageVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          {currentItems.map((product, index) => (
            <Item key={`${index}-${product.countries}`}>
              <Link
                to={`/product/${product.name}?country=${product.countries}`}
                onClick={() => {
                  logEvent(
                    "Portfolio",
                    "click en producto",
                    `Producto: ${product.name}`
                  );
                }}
              >
                {/* Mostrar Skeleton mientras carga */}
                {imageLoading[index] !== false && <SkeletonLoader />}
                <img
                  src={product.image}
                  alt={product.name}
                  style={{
                    display: imageLoading[index] === false ? "block" : "none",
                  }}
                  onLoad={() => handleImageLoad(index)}
                  onError={(e) => handleImageError(e, index)}
                />
              </Link>
              <ProductLink
                to={`/product/${product.name}?country=${product.countries}`}
                onClick={() => {
                  logEvent(
                    "Portfolio",
                    "click en producto",
                    `Producto: ${product.name}`
                  );
                }}
              >
                <h2
                  style={{
                    textTransform: "capitalize",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                  }}
                >
                  {`${titleProduct(product.name)} `}
                </h2>
              </ProductLink>
              {country ? null : (
                <span
                  style={{
                    color: "#a9a9a9",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  País: {product.countries}
                </span>
              )}
            </Item>
          ))}
        </List>
      </AnimatePresence>
      {!showRecommendation && (
        <Pagination>
          <PageButton onClick={handlePreviousPage} disabled={currentPage === 1}>
            Atrás
          </PageButton>
          <span>
            Página <b>{currentPage}</b> de{" "}
            {Math.ceil(products.length / itemsPerPage)}
          </span>
          <PageButton
            onClick={handleNextPage}
            disabled={currentPage >= Math.ceil(products.length / itemsPerPage)}
          >
            Siguiente
          </PageButton>
        </Pagination>
      )}
    </Container>
  );
};

export default ProductsList;
