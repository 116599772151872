import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { BsWhatsapp } from "react-icons/bs";
import { logEvent } from "../../analytics";

const fadeIn = keyframes`
  from { opacity: 0; transform: scale(0.5); }
  to { opacity: 1; transform: scale(1); }
`;

const WhatsAppButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  font-size: 30px;
  border: none;
  cursor: pointer;
  z-index: 1000;
  animation: ${fadeIn} 0.4s ease-out forwards;
  transition: transform 0.3s ease-out, background-color 0.3s ease-out;

  &:hover {
    background-color: #128c7e;
    transform: scale(1.15);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 90px;
  right: 20px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;

  ${WhatsAppButton}:hover + & {
    opacity: 1;
    visibility: visible;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 400px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.red || "red"};
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;

const CountryList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 15px;
`;

const CountryItem = styled.li`
  padding: 10px;
  background: #f9f9f9;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #25d366;
    color: white;
  }
`;

const whatsappNumbers = [
  { country: "Colombia", number: "57-3108147982" },
  { country: "Perú", number: "51-965407186" },
  { country: "Ecuador", number: "593-989563036" },
  { country: "Venezuela", number: "58-4244583420" },
  { country: "Panamá", number: "57-3176612228" },
  { country: "Bolivia", number: "51-997629111" },
];

const FloatingWhatsAppButton = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    logEvent("whatsapp", "clic en botón de WhatsApp", "Apertura del modal"); // Enviar click a GA4
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSelectCountry = (number, country) => {
    logEvent("whatsapp", "selección de país", country); // Enviar país a GA4
    const formattedNumber = number.replace(/[-\s]/g, "");
    window.open(
      `https://api.whatsapp.com/send?phone=${formattedNumber}&text=Hola!%20Estoy%20interesado%20en%20los%20productos%20de%20DISAN%20Agro%20y%20quisiera%20más%20información.`,
      "_blank"
    );
  };

  return (
    <>
      <WhatsAppButton onClick={handleOpenModal}>
        <BsWhatsapp />
      </WhatsAppButton>
      <Tooltip>Contáctanos por WhatsApp 📲</Tooltip>

      {showModal && (
        <ModalOverlay onClick={handleCloseModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h3>Selecciona tu país</h3>
            <CountryList>
              {whatsappNumbers.map(({ country, number }) => (
                <CountryItem
                  key={country}
                  onClick={() => handleSelectCountry(number, country)}
                >
                  {country}
                </CountryItem>
              ))}
            </CountryList>
            <CloseButton onClick={handleCloseModal}>✖</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default FloatingWhatsAppButton;
